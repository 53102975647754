@import '~antd/dist/antd.less';

html {
  @media screen and (min-width: 1300px) {
    font-size: 16px;
  }

  @media screen and (min-width: 600px) and (max-width: 1300px) {
    font-size: 15px;
  }

  @media screen and (min-width: 350px) and (max-width: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 350px) {
    font-size: 11px;
  }

  body {
    background: #F1F1F1;

    @media (prefers-color-scheme: dark) {
      body {
        background: #ffffff;
      }
    }
  }
}

body {
  font-family: 'DM Sans';

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat';
  }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.85);
}

.ant-picker-today-btn {
  color: rgba(0, 0, 0, 0.85);
}

.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DMSans-Bold.eot');
  src: url('./assets/fonts/DMSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/DMSans-Bold.woff2') format('woff2'),
       url('./assets/fonts/DMSans-Bold.woff') format('woff'),
       url('./assets/fonts/DMSans-Bold.ttf') format('truetype'),
       url('./assets/fonts/DMSans-Bold.svg#DMSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DMSans-Italic.eot');
  src: url('./assets/fonts/DMSans-Italic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/DMSans-Italic.woff2') format('woff2'),
       url('./assets/fonts/DMSans-Italic.woff') format('woff'),
       url('./assets/fonts/DMSans-Italic.ttf') format('truetype'),
       url('./assets/fonts/DMSans-Italic.svg#DMSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DMSans-BoldItalic.eot');
  src: url('./assets/fonts/DMSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/DMSans-BoldItalic.woff2') format('woff2'),
       url('./assets/fonts/DMSans-BoldItalic.woff') format('woff'),
       url('./assets/fonts/DMSans-BoldItalic.ttf') format('truetype'),
       url('./assets/fonts/DMSans-BoldItalic.svg#DMSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DMSans-Medium.eot');
  src: url('./assets/fonts/DMSans-Medium.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/DMSans-Medium.woff2') format('woff2'),
       url('./assets/fonts/DMSans-Medium.woff') format('woff'),
       url('./assets/fonts/DMSans-Medium.ttf') format('truetype'),
       url('./assets/fonts/DMSans-Medium.svg#DMSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DMSans-Regular.eot');
  src: url('./assets/fonts/DMSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/DMSans-Regular.woff2') format('woff2'),
       url('./assets/fonts/DMSans-Regular.woff') format('woff'),
       url('./assets/fonts/DMSans-Regular.ttf') format('truetype'),
       url('./assets/fonts/DMSans-Regular.svg#DMSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('./assets/fonts/DMSans-MediumItalic.eot');
  src: url('./assets/fonts/DMSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/DMSans-MediumItalic.woff2') format('woff2'),
       url('./assets/fonts/DMSans-MediumItalic.woff') format('woff'),
       url('./assets/fonts/DMSans-MediumItalic.ttf') format('truetype'),
       url('./assets/fonts/DMSans-MediumItalic.svg#DMSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Bold.eot');
  src: url('./assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-Bold.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-Bold.woff') format('woff'),
       url('./assets/fonts/Montserrat-Bold.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Black.eot');
  src: url('./assets/fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-Black.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-Black.woff') format('woff'),
       url('./assets/fonts/Montserrat-Black.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-BlackItalic.eot');
  src: url('./assets/fonts/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-BlackItalic.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-BlackItalic.woff') format('woff'),
       url('./assets/fonts/Montserrat-BlackItalic.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-BlackItalic.svg#Montserrat-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-BoldItalic.eot');
  src: url('./assets/fonts/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-BoldItalic.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-BoldItalic.woff') format('woff'),
       url('./assets/fonts/Montserrat-BoldItalic.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-BoldItalic.svg#Montserrat-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraBold.eot');
  src: url('./assets/fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-ExtraBold.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-ExtraBold.woff') format('woff'),
       url('./assets/fonts/Montserrat-ExtraBold.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-ExtraBold.svg#Montserrat-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraBoldItalic.eot');
  src: url('./assets/fonts/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-ExtraBoldItalic.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
       url('./assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-ExtraBoldItalic.svg#Montserrat-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraLight.eot');
  src: url('./assets/fonts/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-ExtraLight.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-ExtraLight.woff') format('woff'),
       url('./assets/fonts/Montserrat-ExtraLight.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-ExtraLight.svg#Montserrat-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Italic.eot');
  src: url('./assets/fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-Italic.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-Italic.woff') format('woff'),
       url('./assets/fonts/Montserrat-Italic.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-Italic.svg#Montserrat-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ExtraLightItalic.eot');
  src: url('./assets/fonts/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-ExtraLightItalic.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
       url('./assets/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-ExtraLightItalic.svg#Montserrat-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Light.eot');
  src: url('./assets/fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-Light.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-Light.woff') format('woff'),
       url('./assets/fonts/Montserrat-Light.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-LightItalic.eot');
  src: url('./assets/fonts/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-LightItalic.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-LightItalic.woff') format('woff'),
       url('./assets/fonts/Montserrat-LightItalic.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-LightItalic.svg#Montserrat-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.eot');
  src: url('./assets/fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-Medium.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-Medium.woff') format('woff'),
       url('./assets/fonts/Montserrat-Medium.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-MediumItalic.eot');
  src: url('./assets/fonts/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-MediumItalic.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-MediumItalic.woff') format('woff'),
       url('./assets/fonts/Montserrat-MediumItalic.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-MediumItalic.svg#Montserrat-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.eot');
  src: url('./assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-Regular.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-Regular.woff') format('woff'),
       url('./assets/fonts/Montserrat-Regular.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBold.eot');
  src: url('./assets/fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-SemiBold.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-SemiBold.woff') format('woff'),
       url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-SemiBoldItalic.eot');
  src: url('./assets/fonts/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-SemiBoldItalic.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
       url('./assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-SemiBoldItalic.svg#Montserrat-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-ThinItalic.eot');
  src: url('./assets/fonts/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-ThinItalic.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-ThinItalic.woff') format('woff'),
       url('./assets/fonts/Montserrat-ThinItalic.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-ThinItalic.svg#Montserrat-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Thin.eot');
  src: url('./assets/fonts/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Montserrat-Thin.woff2') format('woff2'),
       url('./assets/fonts/Montserrat-Thin.woff') format('woff'),
       url('./assets/fonts/Montserrat-Thin.ttf') format('truetype'),
       url('./assets/fonts/Montserrat-Thin.svg#Montserrat-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Black.eot');
  src: url('./assets/fonts/Inter-Black.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Inter-Black.woff2') format('woff2'),
       url('./assets/fonts/Inter-Black.woff') format('woff'),
       url('./assets/fonts/Inter-Black.ttf') format('truetype'),
       url('./assets/fonts/Inter-Black.svg#Inter-Black.svg') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.eot');
  src: url('./assets/fonts/Inter-Bold.eot?#iefix') format('embedded-opentype'),
       url('./assets/fonts/Inter-Bold.woff2') format('woff2'),
       url('./assets/fonts/Inter-Bold.woff') format('woff'),
       url('./assets/fonts/Inter-Bold.ttf') format('truetype'),
       url('./assets/fonts/Inter-Bold.svg#Inter-Bold.svg') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@primary-color: #367F98;